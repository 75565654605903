@keyframes morph {
  0% {
    r: 1;
  }
  30% {
    r: 3;
  }
  60% {
    r: 1;
  }
  100% {
    r: 1;
  }
}

.loading-circle-1 {
  animation: morph 1.25s ease infinite;
}

.loading-circle-2 {
  animation: morph 1.25s 0.25s ease infinite;
}

.loading-circle-3 {
  animation: morph 1.25s 0.5s ease infinite;
}
